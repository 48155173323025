<template>
  <div>
    <div class="popup-dim">
      <div class="ccc-popup">
        <i class="close-btn icon-close" @click="closePopup()"></i>
        <p class="title">
          <img src="/img/smsSend/icon/tool_url.png" style="width:20px;object-fit: contain">
          한글 도메인 by 클릭.cc
        </p>
        <p class="desc">한글 도메인으로 변환할 URL을 넣어주세요</p>
        <input type="text" class="url-input" v-model="targetUrl" placeholder="변환할 URL" @input="onTargetUrlChange" />
        <div class="input-wrap" v-show="targetUrl.length > 0">
          <input type="text" placeholder="한글 도메인" v-model="shortId" v-on:input="onShortIdChange">
          <span>.클릭.cc</span>
        </div>
        <p class="mt-1 color-danger" v-show="errMesage">{{ errMesage }}</p>
        <div style="margin-top:20px;text-align: right">
          <button class="btn btn-secondary" @click="closePopup()" style="float:left">취소</button>
          <button class="btn btn-primary preview-btn" @click="preview()" v-show="isCreated">미리보기</button>
          <button class="btn btn-outline-primary use-btn ml-2" @click="use()" v-show="isCreated">사용하기</button>
          <button class="btn btn-primary" v-show="!isCreated" @click="createClick()" :disabled="!canCreate">변환</button>
        </div>
        <p class="mt-2 desc">
          * 생성된 링크의 관리는 <span style="text-decoration: underline;cursor: pointer;" @click="goMyPage">마이페이지</span>에서
          가능합니다.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import ShortenUrlService from '@/services/ShortenUrlService'

export default {
  data() {
    return {
      canCreate: false,
      isLoading: false,
      isValidated: false,
      targetUrl: '',
      shortId: null,
      errMesage: null,
      isCreated: false,
      interval: null,
    };
  },
  mounted() {
  },
  beforeDestroy() {
    clearTimeout(this.interval);
  },
  methods: {
    onShortIdChange(e) {
      this.validateClickId(e.target.value)
    },
    onTargetUrlChange(e) {
      this.canCreate = this.isValidated && e.target.value.length > 0
    },
    async createClick() {
      try {
        if (this.isLoading) {
          return
        }

        this.isLoading = true;
        await ShortenUrlService.createClick(this.shortId, this.targetUrl)
        this.isCreated = true
      }
      catch (e) {
        // do nothing
        this.errMesage = e
      }
      finally {
        this.isLoading = false
      }
    },
    async validateClickId(shortId) {
      clearTimeout(this.interval)
      this.interval = setTimeout(async () => {
        try {
          await ShortenUrlService.validateClickId(shortId)
          this.isValidated = true
          this.canCreate = this.targetUrl.length > 0
          this.errMesage = null
        }
        catch (e) {
          this.isValidated = false
          this.errMesage = e
        }
      }, 200)
    },
    closePopup() {
      this.$emit('close');
    },
    preview() {
      window.open(this.fullShortenUrl)
    },
    use() {
      this.$emit('use-link', this.shortenUrl)
      this.closePopup();
    },
    goMyPage() {
      window.open('/mypage')
    }
  },
  computed: {
    shortenUrl() {
      return this.shortId + '.클릭.cc'
    },
    fullShortenUrl() {
      return 'https://' + this.shortenUrl;
    }
  },
}
</script>
<style lang="scss" scoped>
.popup-dim {
  positioN: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.ccc-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 15px;
  width: 400px;
  transform: translate(-50%, -60%);
  z-index: 9;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;

  .close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 20px;
    padding: 5px;
    color: #333;
    cursor: pointer;
  }

  .title {
    margin: 0;
    font-size: 15px;
    color: var(--color-primary);
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .desc {
    margin: 5px 0 0;
    font-size: 13px;
    color: #999;
  }

  .url-input,
  .input-wrap {
    position: relative;
    margin-top: 10px;
    padding: 5px 10px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    outline: none;
  }

  .input-wrap {
    display: flex;
    align-items: center;
    padding: 5px 0;

    span,
    input {
      width: 100%;
    }

    input {
      padding: 0 10px;
      border: none;
      text-align: left;

      &:focus {
        border: none;
        outline: none;
      }
    }

    span {
      text-align: left;
      color: #999;
    }
  }
}

.color-danger {
  color: var(--color-danger);
}
</style>
