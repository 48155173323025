

import Api from "@/services/Api";

export default {
  delete(id) {
    return Api().delete('/api/shortenUrls/' + id)
  },
  fetchList(page) {
    return Api().get('/api/shortenUrls', { params: { page } })
  },
  createUrl(url) {
    return Api().post('/api/shortenUrls/url', { url })
  },
  createView() {
    return Api().post('/api/shortenUrls/view')
  },
  updateView(shortId, urls) {
    return Api().put('/api/shortenUrls/view', { shortId, urls })
  },
  uploadImage(formData) {
    return Api().post("/api/shortenUrls/uploadImage", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  validateClickId(shortId) {
    return Api(undefined, { hideAlert: true }).get('/api/shortenUrls/validateClickId', { params: { shortId } })
  },
  createClick(shortId, targetUrl) {
    return Api(undefined, { hideAlert: true }).post('/api/shortenUrls/createClick', { shortId, targetUrl })
  }
};
