<template>
  <div>
    <div class="popup-dim">
      <div class="img-upload-popup">
        <i class="close-btn icon-close" @click="closePopup()"></i>
        <p class="title">
          <img src="/img/smsSend/icon/tool_img.png" style="width:20px;object-fit: contain">
          이미지 링크 발송
        </p>
        <p class="desc">이미지를 볼 수 있는 링크를 생성합니다</p>
        <div class="box-container">
          <ul class="upload-imgs">
            <li>
              <div class="bg" @click="showUploadPopup(0)"></div>
              <img :src="imgUrl1" v-show="imgUrl1" />
              <div class="delete-btn" @click="deleteImg(0)" v-show="imgUrl1">
                <span class="icon-close-w"></span>
              </div>
            </li>
            <li>
              <div class="bg" @click="showUploadPopup(1)"></div>
              <img :src="imgUrl2" v-show="imgUrl2" />
              <div class="delete-btn" @click="deleteImg(1)" v-show="imgUrl2">
                <span class="icon-close-w"></span>
              </div>
            </li>
            <li>
              <div class="bg" @click="showUploadPopup(2)"></div>
              <img :src="imgUrl3" v-show="imgUrl3" />
              <div class="delete-btn" @click="deleteImg(2)" v-show="imgUrl3">
                <span class="icon-close-w"></span>
              </div>
            </li>
          </ul>
          <p>
            최대 3장까지 이미지 파일(jpg, png, gif)을 첨부해주세요
            <br />
            권장사이즈 640x960픽셀, 용량 10MB 미만
          </p>
        </div>
        <p class="preview-url">{{ fullShortenUrl }}</p>
        <div style="text-align: right">
          <button class="btn btn-secondary" @click="closePopup()" style="float:left">취소</button>
          <button class="btn btn-primary preview-btn" @click="preview()">미리보기</button>
          <button class="btn btn-outline-primary use-btn ml-2" @click="use()">사용하기</button>
        </div>
        <p class="mt-2 desc">
          * 생성된 링크의 관리는 <span style="text-decoration: underline;cursor: pointer;" @click="goMyPage">마이페이지</span>에서
          가능합니다.
        </p>
      </div>
    </div>
    <ImgUploadItemPopup v-if="showImgItemPopup" :key="popupKey" @close="closeItemPopup" @change-img="changeImg" />
  </div>
</template>
<script>
import ShortenUrlService from '@/services/ShortenUrlService'
import ImgUploadItemPopup from '@/views/components/ImgUploadItemPopup'

export default {
  components: {
    ImgUploadItemPopup
  },
  data() {
    return {
      popupKey: null,
      shortId: null,
      showImgItemPopup: false,
      imgUrl1: null,
      imgUrl2: null,
      imgUrl3: null,
    };
  },
  mounted() {
    this.createView()
  },
  methods: {
    async createView() {
      try {
        const response = await ShortenUrlService.createView()
        this.shortId = response.data.shortId
      }
      catch (e) {
        alert('링크 주소 생성 실패, 관리자에게 문의해주세요')
      }
    },
    async updateView() {

      try {
        let urls = []
        if (this.imgUrl1) {
          urls.push(this.imgUrl1)
        }
        if (this.imgUrl2) {
          urls.push(this.imgUrl2)
        }
        if (this.imgUrl3) {
          urls.push(this.imgUrl3)
        }
        await ShortenUrlService.updateView(this.shortId, urls)


      }
      catch (e) {
        // do nothing
      }
    },
    showUploadPopup(popupKey) {
      if ((popupKey == 0 && this.imgUrl1) || (popupKey == 1 && this.imgUrl2) || (popupKey == 2 && this.imgUrl3)) {
        return;
      }

      this.popupKey = popupKey
      this.showImgItemPopup = true
    },
    closePopup() {
      this.$emit('close');
    },
    preview() {
      window.open('https://' + this.shortenUrl)
    },
    use() {
      this.$emit('use-link', '👉 ' + this.shortenUrl)
      this.closePopup();
    },
    closeItemPopup() {
      this.showImgItemPopup = false
    },
    deleteImg(key) {
      if (key === 0) {
        this.imgUrl1 = null
      }
      else if (key === 1) {
        this.imgUrl2 = null
      }
      else if (key === 2) {
        this.imgUrl3 = null
      }

      this.updateView();
    },
    changeImg(key, imgUrl) {
      console.log(key, imgUrl);
      if (key === 0) {
        this.imgUrl1 = imgUrl
      }
      else if (key === 1) {
        this.imgUrl2 = imgUrl
      }
      else if (key === 2) {
        this.imgUrl3 = imgUrl
      }

      this.updateView();

      this.showImgItemPopup = false
    },
    goMyPage() {
      window.open('/mypage')
    }
  },
  computed: {
    shortenUrl() {
      return '이미지보기.tv/' + this.shortId
    },
    fullShortenUrl() {
      return 'https://' + this.shortenUrl;
    }
  }
}
</script>
<style lang="scss" scoped>
.popup-dim {
  positioN: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.img-upload-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 15px;
  width: 600px;
  transform: translate(-50%, -60%);
  z-index: 9;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;

  .close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 20px;
    padding: 5px;
    color: #333;
    cursor: pointer;
  }

  .title {
    margin: 0;
    font-size: 15px;
    color: var(--color-primary);
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .desc {
    margin: 5px 0 0;
    font-size: 13px;
    color: #999;
  }

  .box-container {
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 15px 30px;
    text-align: center;
    color: #999;

    .upload-imgs {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      gap: 30px;

      li {
        position: relative;
        width: 100px;
        height: 134px;
        background-image: url(/img/smsSend/icon/tool_img_add.png);
        cursor: pointer;

        .bg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          background-color: gray;
          cursor: auto;
          border-radius: 5px;
        }

        .delete-btn {
          position: absolute;
          top: 0;
          right: 0;
          width: 30px;
          height: 30px;
          transform: translate(50%, -50%);
          border-radius: 15px;
          background-color: #333;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background-color 0.2s ease-in-out;
          cursor: pointer;
          border: 2px solid white;
        }

        &:hover {
          .delete-overlay {
            background-color: black;
            ;
          }
        }
      }
    }

    p {
      margin: 15px 0 0;
    }
  }

  .preview-url {
    margin-top: 10px;
    background-color: #f7f7f5;
    border: 1px solid #ccc;
    color: #333;
    padding: 5px;
  }
}
</style>
