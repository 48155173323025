<template>
  <div class="popup-dim" style="background-color: rgba(0, 0, 0, 0.2)">
    <div class="img-upload-item-popup">
      <i class="close-btn icon-close" @click="closePopup()"></i>
      <p class="title">이미지 링크 발송</p>
      <p class="desc">이미지를 볼 수 있는 링크를 생성합니다</p>
      <div class="img-edit-container" v-show="shortUrl === null">
        <div class="left-area">
          <ul>
            <li @click="setTabIndex(0)" :class="{ active: tabIndex === 0 }">
              <img src="/img/smsSend/editor/main1_photo.png">
              <img src="/img/smsSend/editor/main1_photo_on.png">
              <p>이미지</p>
            </li>
            <li @click="setTabIndex(1)" :class="{ active: tabIndex === 1, disabled: !isLoadedImage }">
              <img src="/img/smsSend/editor/main2_emoji.png">
              <img src="/img/smsSend/editor/main2_emoji_on.png">
              <p>스티커</p>
            </li>
            <li @click="setTabIndex(2)" :class="{ active: tabIndex === 2, disabled: !isLoadedImage }">
              <img src="/img/smsSend/editor/main2_text.png">
              <img src="/img/smsSend/editor/main2_text_on.png">
              <p>텍스트</p>
            </li>
            <li @click="setTabIndex(3)" :class="{ active: tabIndex === 3, disabled: !isLoadedImage }">
              <img src="/img/smsSend/editor/main3_crop.png">
              <img src="/img/smsSend/editor/main3_crop_on.png">
              <p>자르기</p>
            </li>
            <li @click="setTabIndex(4)" :class="{ active: tabIndex === 4, disabled: !isLoadedImage }">
              <img src="/img/smsSend/editor/main4_rotate.png">
              <img src="/img/smsSend/editor/main4_rotate_on.png">
              <p>회전</p>
            </li>
          </ul>
        </div>
        <div class="sub-area">
          <ul>
            <li v-show="tabIndex === 0">
              <div>
                <p class="sub-title">이미지 업로드</p>
                <p class="sub-desc">이미지를 업로드 해주세요</p>
                <hr>
                <div class="file-dropzone-wrap">
                  <img src="/img/icon_drag.webp" />
                  <p>이미지를 드래그하거나 <span>클릭</span>해서<br />이미지를 업로드하세요.</p>
                  <div class="img-file-dropzone file-dropzone"> </div>
                </div>
              </div>
              <div v-show="isLoadedImage">
                <hr style="margin-top:50px">
                <p class="sub-title">링크 연결</p>
                <p class="sub-desc">
                  이미지가 잘 업로드 되었습니다! 👏<br />
                  <br />
                  해당 이미지를 클릭 했을 때<br />
                  연결하고 싶은 사이트가 있다면<br />
                  URL 주소를 입력해 주세요. 💫
                </p>
                <hr>
                <input type="url" class="input" placeholder="https://" v-model="linkUrl" />
              </div>
            </li>
            <li v-show="tabIndex === 1">
              <select name="" id="" class="form-control" v-model="emojiSelectVal">
                <option value="0">스마일리</option>
                <option value="1">동물과 자연</option>
                <option value="2">음식과 음료</option>
                <option value="3">활동</option>
                <option value="4">여행과 장소</option>
                <option value="5">사물</option>
                <option value="6">기호</option>
                <option value="7">깃발</option>
              </select>
              <div v-for="(section, sectionIndex) in emojiData" :key="sectionIndex" class="mt-2 emoji-icons"
                v-show="sectionIndex == emojiSelectVal">
                <img :src="'https://static.londonsms.com/img/emoji/' + (sectionIndex + 1) + '/' + item + '.png'" alt=""
                  v-for="(item, itemIndex) in section" :key="itemIndex"
                  @click="addImageObject('https://static.londonsms.com/img/emoji/' + (sectionIndex + 1) + '/' + item + '.png')">
              </div>
            </li>
            <li v-show="tabIndex === 2">
              <p class="sub-title">텍스트</p>
              <p class="sub-desc">불러온 포토위에 더블클릭 하시면 텍스트를 삽입할 수 있습니다.</p>
              <hr>
              <p>글자 크기</p>
              <input type="number" v-model.number="fontSize" />
              <p style="margin-top: 20px;">스타일</p>
              <div class="btn-wrap">
                <button :class="{ active: fontWeight === 'bold' }" @click="toggleFontWeight()">
                  <img src="/img/smsSend/editor/sub2_bold.png" />
                  <p>굵게</p>
                </button>
                <button :class="{ active: fontStyle === 'italic' }" @click="toggleFontStyle()">
                  <img src="/img/smsSend/editor/sub2_tilt.png" />
                  <p>기울임</p>
                </button>
                <button :class="{ active: textDecoration === 'underline' }" @click="toggleTextDecoration()">
                  <img src="/img/smsSend/editor/sub2_underline.png" />
                  <p>밑줄</p>
                </button>
              </div>
              <p style=" margin-top: 20px;">색상</p>
              <input type="color" v-model="textColor" style="width:100%;">
            </li>
            <li v-show="tabIndex === 3">
              <p class="sub-title">자르기</p>
              <p class="sub-desc">원하는 사이즈로 포토를 잘라낼 수 있습니다.</p>
              <p class="sub-desc">자르기 완료 시 반드시 '적용완료' 해주세요</p>
              <hr style="margin-top: 20px">
              <div class="btn-wrap">
                <button @click="setCropzoneRectRatio()" :class="{ active: cropzoneRectRatio === null }">
                  <img src="/img/smsSend/editor/sub3_crop.png" />
                  <p>자유 비율</p>
                </button>
                <button @click=" setCropzoneRectRatio(1)" :class="{ active: cropzoneRectRatio === 1 }">
                  <img src="/img/smsSend/editor/sub3_crop.png" />
                  <p>1:1</p>
                </button>
                <button @click="setCropzoneRectRatio(1.5)" :class="{ active: cropzoneRectRatio === 1.5 }">
                  <img src="/img/smsSend/editor/sub3_crop.png" />
                  <p>3:2</p>
                </button>
                <button @click="setCropzoneRectRatio(1.33)" :class="{ active: cropzoneRectRatio === 1.33 }">
                  <img src="/img/smsSend/editor/sub3_crop.png" />
                  <p>4:3</p>
                </button>
                <button @click="setCropzoneRectRatio(1.25)" :class="{ active: cropzoneRectRatio === 1.25 }">
                  <img src="/img/smsSend/editor/sub3_crop.png" />
                  <p>5:4</p>
                </button>
                <button @click="setCropzoneRectRatio(1.77)" :class="{ active: cropzoneRectRatio === 1.77 }">
                  <img src="/img/smsSend/editor/sub3_crop.png" />
                  <p>16:9</p>
                </button>
              </div>

              <div style="margin-top:20px;text-align: center;" v-show="!isCropped">
                <button class="btn btn-small btn-primary" @click="applyCrop">적용</button>
              </div>
            </li>
            <li v-show="tabIndex === 4">
              <p class="sub-title">회전</p>
              <p class="sub-desc">90° 회전 시, 포토 전체가 회전됩니다.</p>
              <p class="sub-desc">스티커 혹은 텍스트 박스를 선택하시면 개별로 회전할 수 있습니다.</p>
              <hr style="margin-top: 20px">
              <div class="btn-wrap" style="grid-template-columns: 1fr 1fr;">
                <button @click="rotateImage(-90)">
                  <img src="/img/smsSend/editor/sub4_left.png" />
                  <p>왼쪽 90° 회전</p>
                </button>
                <button @click="rotateImage(90)">
                  <img src="/img/smsSend/editor/sub4_right.png" />
                  <p>오른쪽 90° 회전</p>
                </button>
              </div>
            </li>
          </ul>
        </div>
        <div class="main-area">
          <div class="img-area">
            <tui-image-editor ref="tuiImageEditor" :options="options" :include-ui="useDefaultUI" @addText="onAddText"
              @mousedown="onMouseDown" @objectActivated="onObjectActivated" @objectMoved="onObjectMoved"
              @objectScaled="onObjectScaled" @redoStackChanged="onRedoStackChanged" @textEditing="onTextEditing"
              @undoStackChanged="onUndoStackChanged" />
            <span v-show="!isLoadedImage" style="position:absolute;top:50%;left:50%;transform: translate(-50%,-50%);">왼쪽
              툴에서 이미지를 먼저 업로드해주세요.📷</span>
          </div>
          <div class="main-bot-area">
            <button @click="unDo()" :disabled="disableUndoBtn">
              <img src="/img/smsSend/editor/menu_left.png" />
              <span>이전</span>
            </button>
            <button @click="reDo()" :disabled="disableRedoBtn">
              <img src="/img/smsSend/editor/menu_right.png" />
              <span>다음</span>
            </button>
            <button @click="removeActiveObject()">
              <img src="/img/smsSend/editor/menu_del.png" />
              <span>선택 삭제</span>
            </button>
            <button @click="clearObjects()">
              <img src="/img/smsSend/editor/menu_all_del.png" />
              <span>전체 삭제</span>
            </button>
            <!-- <button @click="download()">다운로드</button> -->
            <button class="btn-primary" @click="uploadImage">제작 완료</button>
          </div>
        </div>
      </div>
      <div style="padding:20px 0" v-show="shortUrl !== null">
        <a :href="shortUrl" target="_blank">{{ shortUrl }}</a>
        <img :src="shortUrl" style="margin-top:10px;width:100%" />
      </div>
    </div>
  </div>
</template>
<script>
import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-image-editor/dist/tui-image-editor.css';
import { ImageEditor } from '@toast-ui/vue-image-editor';
import ShortenUrlService from '@/services/ShortenUrlService'
export default {
  components: {
    'tui-image-editor': ImageEditor,
  },
  data() {
    return {
      tabIndex: 0,
      fontSize: 20,
      isLoadedImage: false,
      textColor: '#000',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textDecoration: 'none',
      cropzoneRectRatio: null,
      useDefaultUI: false,
      cropZoneRectRatio: null,
      isCropped: false,
      options: {
        cssMaxWidth: 700,
        cssMaxHeight: 500,
      },
      activeObjectId: null,
      shortUrl: null,
      disableUndoBtn: true,
      disableRedoBtn: true,
      emojiData: [],
      emojiSelectVal: 0,
      linkUrl: ''
    };
  },
  mounted() {
    this.fetchEmojiIcon()
    this.initDropzone();
  },
  methods: {
    initDropzone() {
      const t = this
      new window.Dropzone("div.img-file-dropzone", {
        autoQueue: false,
        url: "/file/fake",
        acceptedFiles: "image/*",
        maxFiles: 1,
        init: function () {

          this.on('addedfile', (file) => {
            if (!file) {
              return
            }

            t.fireEditorEvent('loadImageFromFile', file).then(() => {
              t.$refs.tuiImageEditor.invoke('clearUndoStack');
              t.isLoadedImage = true
            })

            this.removeFile(file);
          });
        }
      });
    },
    async fetchEmojiIcon() {
      try {
        const response = await fetch('https://static.londonsms.com/img/emoji/data2.json')
        this.emojiData = await response.json()
        console.log(this.emojiData)
      }
      catch (e) {
        console.log(e)
      }
    },
    addImageObject(filePath) {
      this.fireEditorEvent('addImageObject', filePath)
    },
    closePopup() {
      this.$emit('close');
    },
    fireEditorEvent(eventName, ...params) {
      try {
        if (!this.$refs.tuiImageEditor) {
          return;
        }

        if (params.length > 0) {
          return this.$refs.tuiImageEditor.invoke(eventName, ...params);
        } else {
          return this.$refs.tuiImageEditor.invoke(eventName);
        }
      }
      catch (e) {
        // do nothing
        console.log(e)
      }
    },
    inputImageChange(event) {
      var supportingFileAPI = !!(window.File && window.FileList && window.FileReader);

      if (!supportingFileAPI) {
        alert('This browser does not support file-api');
      }

      const file = event.target.files[0];

      this.fireEditorEvent('loadImageFromFile', file).then(() => {
        this.$refs.tuiImageEditor.invoke('clearUndoStack');
        this.isLoadedImage = true
      })
    },
    onAddText(pos) {
      const styles = { fontSize: this.fontSize, fill: this.textColor, fontWeight: this.fontWeight, fontStyle: this.fontStyle, textDecoration: this.textDecoration }
      this.fireEditorEvent('addText', '텍스트를 입력하세요', { position: pos.originPosition, styles }).then(function (objectProps) {
        console.log(objectProps);
      });
    },
    onMouseDown() {

    },
    onObjectActivated(obj) {
      this.activeObjectId = obj.id;
    },
    onObjectMoved() {

    },
    onObjectScaled() {

    },
    onTextEditing() {

    },
    onRedoStackChanged(length) {
      this.disableRedoBtn = !length

    },
    onUndoStackChanged(length) {
      this.disableUndoBtn = !length
    },
    unDo() {
      this.fireEditorEvent('discardSelection');
      this.fireEditorEvent('undo');
    },
    reDo() {
      this.fireEditorEvent('discardSelection');
      this.fireEditorEvent('redo');
    },
    clearObjects() {
      this.fireEditorEvent('clearObjects')
    },
    removeActiveObject() {
      this.fireEditorEvent('removeActiveObject')
    },
    setTabIndex(index) {
      if (index > 0 && !this.isLoadedImage) {
        return
      }
      this.tabIndex = index
    },
    toggleFontWeight() {
      this.fontWeight = this.fontWeight === 'bold' ? 'normal' : 'bold'
    },
    toggleFontStyle() {
      this.fontStyle = this.fontStyle === 'italic' ? 'normal' : 'italic'
    },
    toggleTextDecoration() {
      this.textDecoration = this.textDecoration === 'underline' ? 'none' : 'underline'
    },
    setCropzoneRectRatio(mode) {
      this.cropzoneRectRatio = mode
      this.fireEditorEvent('setCropzoneRect', mode)
    },

    applyCrop() {
      this.fireEditorEvent('crop', this.fireEditorEvent('getCropzoneRect')).then(() => {
        this.fireEditorEvent('stopDrawingMode');
        this.isCropped = true

        // var $editor = $('.tui-image-editor');
        // var $container = $('.tui-image-editor-canvas-container');
        // var height = parseFloat($container.css('max-height'));

        // $editor.height(height);
      });
    },
    cancelCrop() {
      this.fireEditorEvent('stopDrawingMode');
    },
    rotateImage(deg) {
      this.fireEditorEvent('rotate', deg)
    },
    download() {
      var imageName = this.fireEditorEvent('getImageName');
      var dataURL = this.fireEditorEvent('toDataURL', { format: 'jpeg' });
      var blob, type, w;

      var supportingFileAPI = !!(window.File && window.FileList && window.FileReader);
      if (supportingFileAPI) {
        blob = this.base64ToBlob(dataURL);
        type = blob.type.split('/')[1];
        if (imageName.split('.').pop() !== type) {
          imageName += '.' + type;
        }

        // Library: FileSaver - saveAs
        window.saveAs(blob, imageName); // eslint-disable-line
      } else {
        alert('This browser needs a file-server');
        w = window.open();
        w.document.body.innerHTML = '<img src=' + dataURL + '>';
      }
    },
    base64ToBlob(data) {
      var rImageType = /data:(image\/.+);base64,/;
      var mimeString = '';
      var raw, uInt8Array, i, rawLength;

      raw = data.replace(rImageType, function (header, imageType) {
        mimeString = imageType;

        return '';
      });

      raw = atob(raw);
      rawLength = raw.length;
      uInt8Array = new Uint8Array(rawLength); // eslint-disable-line

      for (i = 0; i < rawLength; i += 1) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: mimeString });
    },
    async uploadImage() {
      var imageName = this.fireEditorEvent('getImageName');
      var dataURL = this.fireEditorEvent('toDataURL', { format: 'jpeg' });
      var blob, type;

      var supportingFileAPI = !!(window.File && window.FileList && window.FileReader);
      if (supportingFileAPI) {
        blob = this.base64ToBlob(dataURL);
        type = blob.type.split('/')[1];
        if (imageName.split('.').pop() !== type) {
          imageName += '.' + type;
        }
        const formData = new FormData();
        formData.append('file', blob);
        formData.append('linkUrl', this.linkUrl);
        try {
          const response = await ShortenUrlService.uploadImage(formData)
          const shortUrl = 'https://이미지보기.tv/' + response.data.item.shortId

          this.$emit('change-img', this.$vnode.key, shortUrl)
        }
        catch (e) {
          // do nothing
        }
      }
    },
  },
  watch: {
    emojiSelectVal(a) {
      console.log(a)
    },
    emojiData() { },
    tabIndex(newTabIndex) {
      if (newTabIndex === 2) {
        this.fireEditorEvent('startDrawingMode', 'TEXT');
      }
      else if (newTabIndex === 3) {
        this.cropzoneRectRatio = null
        this.isCropped = false
        this.fireEditorEvent('startDrawingMode', 'CROPPER');
      }
      else {
        this.fireEditorEvent('stopDrawingMode');
      }
    },
    textColor(newTextColor) {
      this.fireEditorEvent('changeTextStyle', this.activeObjectId, { fill: newTextColor })
    },
    fontSize(newFontSize) {
      this.fireEditorEvent('changeTextStyle', this.activeObjectId, { fontSize: newFontSize })
    },
    fontWeight(newFontWeight) {
      this.fireEditorEvent('changeTextStyle', this.activeObjectId, { fontWeight: newFontWeight })
    },
    fontStyle(newFontStyle) {
      this.fireEditorEvent('changeTextStyle', this.activeObjectId, { fontStyle: newFontStyle })
    },
    textDecoration(newTextDecoration) {
      this.fireEditorEvent('changeTextStyle', this.activeObjectId, { textDecoration: newTextDecoration })
    }
  }
}
</script>
<style lang="scss" scoped>
.img-upload-item-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 15px;
  width: 1000px;
  transform: translate(-50%, -60%);
  z-index: 9;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1100;

  .close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 20px;
    padding: 5px;
    color: #333;
    cursor: pointer;
  }

  .title {
    margin: 0;
    font-size: 15px;
    color: var(--color-primary);
    font-weight: 500;
  }

  .desc {
    margin: 5px 0 0;
    font-size: 13px;
    color: #999;
  }

  .img-edit-container {
    margin-top: 20px;
    width: 100%;
    height: 600px;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 10px;

    .left-area {
      width: 100px;
      border-right: 1px solid #ccc;

      ul li {
        width: 100%;
        height: 80px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px solid #ccc;
        color: #666;
        cursor: pointer;
        transition: all .2s ease-in-out;

        >img:first-of-type {
          display: block;
        }

        >img:last-of-type {
          display: none;
        }

        p {
          margin: 5px 0 0;
        }

        &.disabled {
          cursor: not-allowed;
          background-color: #f2f4f5;
          opacity: 0.4;
        }

        &.active {
          background-color: #009bfa;
          color: white;

          >img:first-of-type {
            display: none;
          }

          >img:last-of-type {
            display: block;
          }
        }

        &:not(.active):hover {
          background-color: #f4f4f4;
        }
      }
    }

    .sub-area {
      width: 250px;
      padding: 10px;
      box-sizing: border-box;
      background-color: white;
      border-right: 1px solid #ccc;
      overflow: auto;

      .emoji-icons {
        margin-bottom: 15px;

        img {
          width: 30px;
          height: 30px;
          margin: 3px;
        }
      }

      p {
        margin: 0;
      }

      .sub-title {
        margin: 0 0 5px;
        font-size: 15px;
        font-weight: 500;
      }

      .sub-desc {
        margin: 0 0 3px;
        font-size: 15px;
        font-size: 13px;
        color: #999;
      }

      .btn-wrap {
        margin-top: 5px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;

        button {
          border: none;
          background-color: transparent;
          outline: none;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          white-space: nowrap;
          flex-flow: column;
          gap: 5px;
          border-radius: 5px;

          &:hover,
          &.active {
            background-color: #f4f4f4;
          }
        }
      }
    }

    .main-area {
      flex-grow: 1;
      background-color: #f7f7f7;
      display: flex;
      flex-flow: column;

      .img-area {
        position: relative;
        flex-grow: 1;

        >div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .main-bot-area {
        border-top: 1px solid #ccc;
        height: 60px;
        display: flex;
        justify-content: flex-end;

        button {
          display: flex;
          gap: 2px;
          align-items: center;
          justify-content: center;
          border: none;
          border-left: 1px solid #ccc;
          outline: none;
          min-width: 60px;
          padding: 0 10px;

          &:disabled img {
            opacity: 0.3;
          }
        }
      }
    }
  }

  input {
    margin: 10px 0 0;
    padding: 0px 10px;
    width: 100%;
    border: 1px solid #ccc;
  }

  .file-dropzone-wrap {
    background-color: var(--color-flat-white);
    position: relative;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    gap: 5px;
    width: 100%;
    border: 1px dashed var(--color-grey-200);
    padding: 20px 10px;
    cursor: pointer;

    .file-dropzone {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    p {
      text-align: center;
      font-size: 13px;
      margin: 0;

      &:first-of-type {
        margin-top: 10px;
        font-weight: 600;

        span {
          color: var(--color-primary);
        }
      }
    }
  }
}
</style>
